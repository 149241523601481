import { useEffect } from 'react'

const useTidioChat = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = process.env.REACT_APP_TIDIO
    script.async = true
    setTimeout(() => document.body.appendChild(script), 8000)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

export default useTidioChat
