
import React, { useEffect, useState } from 'react'

export default function NavBar () {
  const [showServices, setShowServices] = useState(false)
  useEffect(() => {
    let scrollPosition = window.scrollY
    const header = document.getElementById('header')
    const logo = document.getElementById('logo')
    const navContent = document.getElementById('nav-content')
    const toToggle2 = document.querySelectorAll('.nav-items-toggle')
    const navToggle = document.getElementById('nav-toggle')

    document.addEventListener('scroll', function () {
      /* Apply classes for slide in bar */
      scrollPosition = window.scrollY

      if (scrollPosition > 10) {
        header.classList.add('bg-bh-white')
        header.classList.remove('gradient')
        header.classList.add('shadow')
        logo.classList.add('text-dark-blue')
        logo.classList.add('border-dark-blue')
        logo.classList.remove('text-bh-white')
        navToggle.classList.add('text-dark-blue')
        navToggle.classList.add('border-dark-blue')
        navToggle.classList.remove('text-bh-white')

        for (let i = 0; i < toToggle2.length; i++) {
          toToggle2[i].classList.add('text-dark-blue')
          toToggle2[i].classList.add('border-dark-blue')
          toToggle2[i].classList.remove('lg:text-bh-white')
          toToggle2[i].classList.remove('text-bh-white')
        }
        navContent.classList.add('bg-bh-white')
      } else {
        header.classList.remove('bg-bh-white')
        logo.classList.add('text-bh-white')
        logo.classList.remove('text-dark-blue')
        navToggle.classList.add('text-bh-white')
        navToggle.classList.remove('text-dark-blue')

        for (let i = 0; i < toToggle2.length; i++) {
          if (window.innerWidth > 768) {
            toToggle2[i].classList.add('text-bh-white')
            toToggle2[i].classList.remove('text-dark-blue')
          }
        }

        header.classList.remove('shadow')
        navContent.classList.remove('bg-bh-white')
        navContent.classList.add('bg-gray-100')
      }
    })

    const navMenuDiv = document.getElementById('nav-content')
    const navMenu = document.getElementById('nav-toggle')

    document.onclick = check
    function check (e) {
      const target = (e && e.target)

      // Nav Menu
      if (!checkParent(target, navMenuDiv)) {
        // click NOT on the menu
        if (checkParent(target, navMenu)) {
          // click on the link
          if (navMenuDiv.classList.contains('hidden')) {
            navMenuDiv.classList.remove('hidden')
          } else {
            navMenuDiv.classList.add('hidden')
          }
        } else {
          // click both outside link and outside menu, hide menu
          navMenuDiv.classList.add('hidden')
        }
      }
    }
    function checkParent (t, elm) {
      while (t.parentNode) {
        if (t === elm) {
          return true
        }
        t = t.parentNode
      }
      return false
    }
  })

  return (
    <nav id='header' className='fixed w-full z-10 top-0 text-white gradient'>
      <div className='max-w-5xl container mx-auto flex flex-wrap items-center justify-between mt-0 py-2'>
        {/* <a className='toggleColour flex items-center flex-shrink-0 text-bh-white mr-12 hover:text-light-blue cursor-pointer p-2 ml-2' onClick={() => { window.location.href = '/' }}> */}
        <a href='/' id='logo' className='toggleColour flex items-center flex-shrink-0 text-bh-white mr-12 hover:text-light-blue cursor-pointer p-2 ml-2'>
          <svg className='fill-current h-9 w-9 mr-3' viewBox='0 0 17.55 17.55'><rect className='cls-1' x='7.34' y='14.38' width='3.22' height='3.17' /><polygon className='cls-1' points='17.55 6.89 8.78 0 0 6.89 0 17.55 3.93 17.55 3.93 8.01 7.34 5.58 7.34 11.52 10.57 11.52 10.57 5.58 13.98 8.01 13.98 17.55 17.55 17.55 17.55 6.89' /></svg>
          <span className='font-semibold text-2xl tracking-tight mt-4'>BlueHaus</span>
        </a>
        {/* </a> */}
        <div className='block lg:hidden mr-4'>
          <button id='nav-toggle' className='flex items-center px-3 py-2 border rounded text-bh-white'>
            <svg className='fill-current h-3 w-3' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><title>Menu</title><path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' /></svg>
          </button>
        </div>
        <div id='nav-content' className='w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent p-4 lg:p-0 z-20'>
          <ul className='list-reset lg:flex justify-end flex-1 items-center'>
            <li className='mr-3'>
              <a href='/' className='inline-block nav-items-toggle lg:text-bh-white text-dark-blue no-underline hover:underline py-2 px-4'>Inicio</a>
            </li>
            <li className='mr-3'>
              <a href='/nosotros' className='inline-block nav-items-toggle lg:text-bh-white text-dark-blue no-underline hover:underline py-2 px-4'>Nosotros</a>
            </li>
            <li className='mr-3'>
              <a href='/servicios' className='inline-block nav-items-toggle lg:text-bh-white text-dark-blue no-underline hover:underline py-2 px-4'>Servicios</a>
            </li>
            <li className='mr-3'>
              <a href='/expats' className='inline-block nav-items-toggle lg:text-bh-white text-dark-blue no-underline hover:underline py-2 px-4'>Expats</a>
            </li>
            <li className='mr-3'>
              <a href='/contacto' className='inline-block nav-items-toggle lg:text-bh-white text-dark-blue no-underline hover:underline py-2 px-4'>Contacto</a>
            </li>
          </ul>
        </div>
      </div>
      <hr className='border-b border-gray-100 opacity-25 my-0 py-0' />
    </nav>
  )
}
