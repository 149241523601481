export default function Title ({ title }) {
  return (
    <div>
      <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black mt-8 sm:pt-0'>{title}</h1>
      <div className='w-full mb-4'>
        <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
      </div>
    </div>
  )
}
