import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Title from '../components/TitleWithLine'
import { ReactComponent as Transition1 } from '../assets/transition1.svg'

import React, { useEffect } from 'react'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'

export default function Privacy () {
  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])
  useTidioChat()

  return (
    <div>
      <NavBar />
      <main className='pt-24'>
        <h1 className='pb-16 text-6xl md:text-8xl font-bold leading-tight text-center'>Políticas de Privacidad</h1>
        <div className='relative -mt-12 lg:-mt-24' />
        <Transition1 />
        <section className='bg-white px-2 py-6 sm:p-10'>
          <div className='max-w-4xl mx-auto text-gray-600 text-justify'>
            <p>Esta política de privacidad ("Política") describe cómo la información de identificación personal ("Información personal") que puede proporcionar en el sitio web bluehaus.es ("Sitio web" o "Servicio") y cualquiera de sus productos y servicios relacionados (colectivamente, " Servicios") se recopila, protege y utiliza. También describe las opciones disponibles para usted con respecto a nuestro uso de su información personal y cómo puede acceder y actualizar esta información. Esta Política es un acuerdo legalmente vinculante entre usted ("Usuario", "usted" o "su") y Westshore Engineering Ltd, el propietario de la marca BlueHaus. ("Westshore Engineering Ltd.", "nosotros", "nos" o "nuestro"). Al acceder y utilizar el sitio web y los servicios, usted reconoce que ha leído, entendido y acepta estar sujeto a los términos de este Acuerdo. Esta Política no se aplica a las prácticas de las empresas que no poseemos ni controlamos, ni a las personas que no empleamos ni administramos.</p>
            <Title title='Recopilación automática de información' />
            <p className='mb-2'>Cuando abre el sitio web, nuestros servidores registran automáticamente la información que envía su navegador. Estos datos pueden incluir información como la dirección IP de su dispositivo, el tipo y la versión del navegador, el tipo y la versión del sistema operativo, las preferencias de idioma o la página web que estaba visitando antes de llegar al sitio web y los servicios, las páginas del sitio web y los servicios que visita, el tiempo que pasa en esas páginas, la información que busca en el sitio web, las horas y fechas de acceso y otras estadísticas.</p>
            <p>La información recopilada automáticamente se usa solo para identificar posibles casos de abuso y establecer información estadística sobre el uso y el tráfico del sitio web y los servicios. Esta información estadística no se agrega de otra manera de tal manera que identifique a cualquier usuario particular del sistema.</p>
            <Title title='Recopilación de información personal' />
            <p className='mb-4'>Puede acceder y utilizar el sitio web y los servicios sin decirnos quién es ni revelar ninguna información mediante la cual alguien pueda identificarlo como una persona específica e identificable. Sin embargo, si desea utilizar algunas de las funciones del sitio web, es posible que se le solicite que proporcione cierta información personal (por ejemplo, su nombre y dirección de correo electrónico). Recibimos y almacenamos cualquier información que nos proporcione a sabiendas cuando crea una cuenta, publica contenido, realiza una compra o completa cualquier formulario en línea en el sitio web. Cuando sea necesario, esta información puede incluir lo siguiente:</p>
            <ul className='list-disc ml-8 mb-4'>
              <li>Datos personales como nombre, país de residencia, etc.</li>
              <li>Información de contacto como dirección de correo electrónico, domicilio, etc.</li>
              <li>Detalles de la cuenta, como nombre de usuario, ID de usuario único, contraseña, etc.</li>
              <li>Prueba de identidad como una fotocopia de una identificación del gobierno</li>
              <li>Información de pago como datos de tarjetas de crédito, datos bancarios, etc.</li>
              <li>Datos de geolocalización como latitud y longitud.</li>
              <li>Información sobre otras personas, como familiares, amigos, etc.</li>
              <li>Cualquier otro material que nos envíe voluntariamente, como artículos, imágenes, comentarios, etc.</li>
            </ul>
            <p>Parte de la información que recopilamos proviene directamente de usted a través del sitio web y los servicios. Sin embargo, también podemos recopilar información personal sobre usted de otras fuentes, como bases de datos públicas y nuestros socios de marketing conjuntos. Puede optar por no proporcionarnos su información personal, pero es posible que no pueda aprovechar algunas de las funciones del sitio web. Los usuarios que no estén seguros de qué información es obligatoria pueden ponerse en contacto con nosotros.Parte de la información que recopilamos proviene directamente de usted a través del sitio web y los servicios. Sin embargo, también podemos recopilar información personal sobre usted de otras fuentes, como bases de datos públicas y nuestros socios de marketing conjuntos. Puede optar por no proporcionarnos su información personal, pero es posible que no pueda aprovechar algunas de las funciones del sitio web. Los usuarios que no estén seguros de qué información es obligatoria pueden ponerse en contacto con nosotros.</p>
            <Title title='Uso y procesamiento de la información recopilada' />
            <p className='mb-4'>Para que el sitio web y los servicios estén disponibles para usted, o para cumplir con una obligación legal, necesitamos recopilar y utilizar cierta información personal. Si no proporciona la información que solicitamos, es posible que no podamos proporcionarle los productos o servicios solicitados. Cualquiera de la información que recopilamos de usted puede ser utilizada para los siguientes propósitos:</p>
            <ul className='list-disc ml-8 mb-4'>
              <li>Crear y administrar cuentas de usuario</li>
              <li>Cumplir y gestionar pedidos.</li>
              <li>Entregar productos o servicios.</li>
              <li>Mejorar productos y servicios.</li>
              <li>Enviar información administrativa</li>
              <li>Enviar comunicaciones de marketing y promocionales.</li>
              <li>Responder consultas y ofrecer soporte.</li>
              <li>Solicitar comentarios de los usuarios</li>
              <li>Mejorar la experiencia del usuario</li>
              <li>Publicar testimonios de clientes.</li>
              <li>Entregar publicidad dirigida</li>
              <li>Administrar sorteos y concursos.</li>
              <li>Hacer cumplir los términos y condiciones y políticas</li>
              <li>Proteger de abusos y usuarios maliciosos</li>
              <li>Responder a solicitudes legales y prevenir daños</li>
              <li>Ejecutar y operar el sitio web y los servicios</li>
            </ul>
            <p className='mb-4'>El procesamiento de su información personal depende de cómo interactúe con el sitio web y los servicios, dónde se encuentra en el mundo y si se aplica uno de los siguientes: (i) ha dado su consentimiento para uno o más propósitos específicos; esto, sin embargo, no se aplica, siempre que el procesamiento de Información personal esté sujeto a la Ley de Privacidad del Consumidor de California o la ley europea de protección de datos; (ii) el suministro de información es necesario para la ejecución de un acuerdo con usted y/o para cualquier obligación precontractual del mismo; (iii) el tratamiento es necesario para el cumplimiento de una obligación legal a la que ich eres sujeto; (iv) el procesamiento está relacionado con una tarea que se lleva a cabo en interés público o en el ejercicio de la autoridad oficial que se nos ha conferido; (v) el procesamiento es necesario para los fines de los intereses legítimos perseguidos por nosotros o por un tercero.</p>
            <p>Tenga en cuenta que, según algunas legislaciones, es posible que se nos permita procesar información hasta que se oponga a dicho procesamiento (optándose por no participar), sin tener que depender del consentimiento ni de ninguna otra de las siguientes bases legales a continuación. En cualquier caso, estaremos encantados de aclarar la base legal específica que se aplica al procesamiento y, en particular, si el suministro de Información personal es un requisito legal o contractual, o un requisito necesario para celebrar un contrato.</p>

            <Title title='Facturación y pagos' />
            <p>Utilizamos procesadores de pago de terceros para que nos ayuden a procesar su información de pago de forma segura. El uso de su Información personal por parte de dichos procesadores de terceros se rige por sus respectivas políticas de privacidad, que pueden o no contener protecciones de privacidad tan protectoras como esta Política. Le sugerimos que revise sus respectivas políticas de privacidad.</p>

            <Title title='Gestión de la información' />
            <p>Puede eliminar cierta información personal que tenemos sobre usted. La información personal que puede eliminar puede cambiar a medida que cambian el sitio web y los servicios. Sin embargo, cuando elimina información personal, podemos mantener una copia de la información personal sin revisar en nuestros registros durante el tiempo necesario para cumplir con nuestras obligaciones con nuestros afiliados y socios, y para los fines que se describen a continuación. Si desea eliminar su información personal o eliminar permanentemente su cuenta, puede hacerlo poniéndose en contacto con nosotros.</p>

            <Title title='Revelación de información' />
            <p className='mb-4'>Dependiendo de los Servicios solicitados o según sea necesario para completar cualquier transacción o brindar cualquier servicio que haya solicitado, podemos compartir su información con su consentimiento con nuestros terceros de confianza que trabajan con nosotros, cualquier otra afiliada y subsidiaria en la que confiamos para ayudar en la funcionamiento del sitio web y los servicios disponibles para usted. No compartimos información personal con terceros no afiliados. Estos proveedores de servicios no están autorizados a usar o divulgar su información, excepto cuando sea necesario para realizar servicios en nuestro nombre o cumplir con los requisitos legales. Podemos compartir su Información personal para estos fines solo con terceros cuyas políticas de privacidad sean consistentes con las nuestras o que acepten cumplir con nuestras políticas con respecto a la Información personal. Estos terceros reciben Información personal que necesitan solo para realizar sus funciones designadas, y no los autorizamos a usar o divulgar Información personal para su propio marketing u otros fines.</p>
            <p className='mb-4'>Divulgaremos cualquier Información personal que recopilemos, usemos o recibamos si así lo requiere o lo permite la ley, como para cumplir con una citación o un proceso legal similar, y cuando creamos de buena fe que la divulgación es necesaria para proteger nuestros derechos, proteger su seguridad o la seguridad de otros, investigar fraudes o responder a una solicitud del gobierno.</p>
            <p>En el caso de que pasemos por una transición comercial, como una fusión o adquisición por parte de otra empresa, o la venta de todos o una parte de sus activos, su cuenta de usuario y la Información personal probablemente estarán entre los activos transferidos.</p>
            <Title title='Retención de información' />
            <p>Retendremos y utilizaremos su información personal durante el período necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos, a menos que la ley exija o permita un período de retención más largo. Podemos usar cualquier dato agregado derivado de su Información personal o que la incorpore después de que la actualice o la elimine, pero no de una manera que lo identifique personalmente. Una vez que expire el período de retención, la información personal se eliminará. Por tanto, el derecho de acceso, el derecho de supresión, el derecho de rectificación y el derecho a la portabilidad de los datos no podrán ser ejercitados una vez transcurrido el plazo de conservación.</p>
            {/* <Title title="Transferencia de información" /> */}
            <p>Dependiendo de su ubicación, las transferencias de datos pueden implicar la transferencia y el almacenamiento de su información en un país que no sea el suyo. Tiene derecho a conocer la base legal de las transferencias de información a un país fuera de la Unión Europea o a cualquier organización internacional regida por el derecho internacional público o establecida por dos o más países, como la ONU, y sobre las medidas de seguridad tomadas por nosotros para salvaguardar su información. Si se lleva a cabo dicha transferencia, puede obtener más información consultando las secciones correspondientes de esta Política o consultarnos utilizando la información proporcionada en la sección de contacto.</p>
            <Title title='Los derechos de los usuarios' />
            <p>Puede ejercer ciertos derechos con respecto a su información procesada por nosotros. En particular, tiene derecho a hacer lo siguiente: (i) tiene derecho a retirar el consentimiento cuando haya dado previamente su consentimiento para el procesamiento de su información; (ii) tiene derecho a oponerse al procesamiento de su información si el procesamiento se lleva a cabo en una base legal distinta del consentimiento; (iii) tiene derecho a saber si la información está siendo procesada por nosotros, obtener información sobre ciertos aspectos del procesamiento y obtener una copia de la información que se está procesando; (iv) tiene derecho a verificar la exactitud de su información y solicitar que se actualice o corrija; (v) tiene derecho, bajo ciertas circunstancias, a restringir el procesamiento de su información, en cuyo caso, no procesaremos su información para ningún otro propósito que no sea el de almacenarla; (vi) usted tiene derecho, bajo ciertas circunstancias, a obtener de nosotros la eliminación de su información personal; (vii) tiene derecho a recibir su información en un formato estructurado, de uso común y lectura mecánica y, si es técnicamente posible, a que se transmita a otro controlador sin ningún impedimento. Esta disposición es aplicable siempre que su información sea procesada por medios automatizados y que el procesamiento se base en su consentimiento, en un contrato del que forma parte o en obligaciones precontractuales del mismo.</p>
            <Title title='Los derechos a oponerse al procesamiento' />
            <p>Cuando la información personal se procese para el interés público, en el ejercicio de una autoridad oficial que se nos haya conferido o para los fines de los intereses legítimos que perseguimos, puede oponerse a dicho procesamiento proporcionando un motivo relacionado con su situación particular para justificar el objeción. Debe saber que, sin embargo, si su información personal se procesa con fines de marketing directo, puede oponerse a ese procesamiento en cualquier momento sin proporcionar ninguna justificación. Para saber si estamos procesando información personal con fines de marketing directo, puede consultar las secciones correspondientes de este documento.</p>
            <Title title='Derechos de protección de datos bajo GDPR' />
            <p className='mb-4'>Si es residente del Espacio Económico Europeo (EEE), tiene ciertos derechos de protección de datos y Westshore Engineering Ltd. tiene como objetivo tomar medidas razonables para permitirle corregir, modificar, eliminar o limitar el uso de su información personal. Si desea que se le informe qué información personal tenemos sobre usted y si desea que se elimine de nuestros sistemas, comuníquese con nosotros. En determinadas circunstancias, usted tiene los siguientes derechos de protección de datos:</p>
            <ul className='list-disc ml-8 mb-4'>
              <li>Tiene derecho a solicitar acceso a su Información personal que almacenamos y tiene la capacidad de acceder a su Información personal.</li>
              <li>Tiene derecho a solicitar que corrijamos cualquier Información personal que crea que es inexacta. También tiene derecho a solicitarnos que completemos la información personal que cree que está incompleta</li>
              <li>Tiene derecho a solicitar el borrado de su Información personal bajo ciertas condiciones de esta Política</li>
              <li>Tiene derecho a oponerse a que procesemos su información personal</li>
              <li>Tiene derecho a buscar restricciones en el procesamiento de su información personal. Cuando restringe el procesamiento de su información personal, podemos almacenarla, pero no la procesaremos más.</li>
              <li>Tiene derecho a recibir una copia de la información que tenemos sobre usted en un formato estructurado, legible por máquina y de uso común</li>
              <li>Tuambién tiene derecho a retirar su consentimiento en cualquier momento en el que Westshore Engineering Ltd. se haya basado en su consentimiento para procesar su información personal.</li>
            </ul>
            <p>Tiene derecho a quejarse ante una autoridad de protección de datos sobre nuestra recopilación y uso de su información personal. Para obtener más información, comuníquese con su autoridad local de protección de datos en el Espacio Económico Europeo (EEE)</p>
            <Title title='Derechos de privacidad de California' />
            <p>Además de los derechos que se explican en esta Política, los residentes de California que proporcionen Información personal (como se define en el estatuto) para obtener productos o servicios para uso personal, familiar o doméstico tienen derecho a solicitar y obtener de nosotros, una vez al año calendario , información sobre la Información personal que compartimos, si la hubiere, con otras empresas para usos de marketing. Si corresponde, esta información incluiría las categorías de Información personal y los nombres y direcciones de las empresas con las que compartimos dicha información personal para el año calendario inmediatamente anterior (por ejemplo, las solicitudes realizadas en el año actual recibirán información sobre el año anterior) . Para obtener esta información por favor contáctenos.</p>
            <Title title='Cómo ejercer estos derechos' />
            <p>Cualquier solicitud para ejercer sus derechos puede dirigirse a Westshore Engineering Ltd. a través de los datos de contacto proporcionados en este documento. Tenga en cuenta que podemos pedirle que verifique su identidad antes de responder a dichas solicitudes. Su solicitud debe proporcionar información suficiente que nos permita verificar que usted es la persona que dice ser o que es el representante autorizado de dicha persona. Debe incluir detalles suficientes para permitirnos comprender adecuadamente la solicitud y responder a ella. No podemos responder a su solicitud ni proporcionarle Información personal a menos que primero verifiquemos su identidad o autoridad para realizar dicha solicitud y confirmemos que la Información personal en se relaciona con usted.</p>
            <Title title='Privacidad de los niños' />
            <p>No recopilamos a sabiendas ninguna información personal de niños menores de 18 años. Si es menor de 18 años, no envíe ninguna información personal a través del sitio web y los servicios. Alentamos a los padres y tutores legales a controlar el uso de Internet de sus hijos y ayudar a hacer cumplir esta Política instruyendo a sus hijos para que nunca proporcionen información personal a través del sitio web y los servicios sin su permiso. Si tiene motivos para creer que un niño menor de 18 años nos ha proporcionado información personal a través del sitio web y los servicios, comuníquese con nosotros. También debe tener al menos 16 años de edad para dar su consentimiento para el procesamiento de su información personal en su país (en algunos países podemos permitir que su padre o tutor lo haga en su nombre).</p>
            <Title title='Galletas' />
            <p className='mb-4'>El sitio web y los servicios utilizan "cookies" para ayudar a personalizar su experiencia en línea. Una cookie es un archivo de texto que un servidor de páginas web coloca en su disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a su computadora. Las cookies se le asignan de forma única y solo puede leerlas un servidor web en el dominio que le emitió la cookie.</p>
            <p>Podemos usar cookies para recopilar, almacenar y rastrear información con fines estadísticos para operar el sitio web y los servicios. Tiene la posibilidad de aceptar o rechazar las cookies. La mayoría de los navegadores web aceptan automáticamente las cookies, pero generalmente puede modificar la configuración de su navegador para rechazar las cookies si lo prefiere. Si elige rechazar las cookies, es posible que no pueda experimentar completamente las características del sitio web y los servicios. Para obtener más información sobre las cookies y cómo administrarlas, visite internetcookies.org</p>
            <Title title='Señales de No rastrear' />
            <p>Algunos navegadores incorporan una función No rastrear que indica a los sitios web que visita que no desea que se rastree su actividad en línea. El seguimiento no es lo mismo que usar o recopilar información en relación con un sitio web. A estos efectos, el seguimiento se refiere a la recopilación de información de identificación personal de los consumidores que usan o visitan un sitio web o un servicio en línea a medida que navegan por diferentes sitios web a lo largo del tiempo. El sitio web y los servicios no rastrean a sus visitantes a lo largo del tiempo y en sitios web de terceros. Sin embargo, algunos sitios de terceros pueden realizar un seguimiento de sus actividades de navegación cuando le brindan contenido, lo que les permite adaptar lo que le presentan.</p>
            <Title title='Anuncios' />
            <p className='mb-4'>Podemos mostrar anuncios en línea y podemos compartir información agregada y no identificable sobre nuestros clientes que nosotros o nuestros anunciantes recopilamos a través de su uso del sitio web y los servicios. No compartimos información de identificación personal sobre clientes individuales con los anunciantes. En algunos casos, podemos usar esta información agregada y no identificable para entregar anuncios personalizados a la audiencia prevista.</p>
            <p>También podemos permitir que ciertas empresas de terceros nos ayuden a personalizar la publicidad que creemos que puede ser de interés para los usuarios y recopilar y utilizar otros datos sobre las actividades de los usuarios en el sitio web. Estas empresas pueden publicar anuncios que pueden colocar cookies y rastrear el comportamiento del usuario.</p>
            <Title title='Afiliados' />
            <p>Podemos divulgar información sobre usted a nuestros afiliados con el fin de poder ofrecerle productos y servicios relacionados o adicionales. Cualquier información relacionada con usted que proporcionemos a nuestros afiliados será tratada por dichos afiliados de acuerdo con los términos de esta Política.</p>
            <Title title='Correo de propaganda' />
            <p className='mb-4'>Ofrecemos boletines electrónicos a los que puede suscribirse voluntariamente en cualquier momento. Nos comprometemos a mantener la confidencialidad de su dirección de correo electrónico y no divulgaremos su dirección de correo electrónico a terceros, excepto según lo permitido en la sección de uso y procesamiento de información o con el fin de utilizar un proveedor externo para enviar dichos correos electrónicos. Mantendremos la información enviada por correo electrónico de acuerdo con las leyes y regulaciones aplicables.</p>
            <p>De conformidad con la Ley CAN-SPAM, todos los correos electrónicos enviados por nosotros indicarán claramente de quién es el correo electrónico y proporcionarán información clara sobre cómo contactar al remitente. Puede optar por dejar de recibir nuestro boletín informativo o correos electrónicos de marketing siguiendo las instrucciones para darse de baja que se incluyen en estos correos electrónicos o poniéndose en contacto con nosotros. Sin embargo, seguirá recibiendo correos electrónicos transaccionales esenciales.</p>
            <Title title='Enlaces a otros recursos' />
            <p>El sitio web y los servicios contienen enlaces a otros recursos que no son de nuestra propiedad ni están controlados por nosotros. Tenga en cuenta que no somos responsables de las prácticas de privacidad de dichos otros recursos o terceros. Lo alentamos a que esté atento cuando abandone el sitio web y los servicios y que lea las declaraciones de privacidad de todos y cada uno de los recursos que pueden recopilar información personal.</p>
            <Title title='Seguridad de información' />
            <p>Protegemos la información que proporciona en servidores informáticos en un entorno controlado y seguro, protegido contra el acceso, uso o divulgación no autorizados. Mantenemos medidas de seguridad administrativas, técnicas y físicas razonables en un esfuerzo por proteger contra el acceso no autorizado. s, uso, modificación y divulgación de la Información Personal bajo su control y custodia. Sin embargo, no se puede garantizar la transmisión de datos a través de Internet o de una red inalámbrica. Por lo tanto, mientras nos esforzamos por proteger su información personal, usted reconoce que (i) existen limitaciones de seguridad y privacidad en Internet que están fuera de nuestro control; (ii) no se puede garantizar la seguridad, integridad y privacidad de toda la información y los datos intercambiados entre usted y el Sitio web y los Servicios; y (iii) dicha información y datos pueden ser vistos o manipulados en tránsito por un tercero, a pesar de los mejores esfuerzos.</p>
            <Title title='Filtración de datos' />
            <p>En el caso de que nos demos cuenta de que la seguridad del sitio web y los servicios se ha visto comprometida o que la información personal de los usuarios se ha divulgado a terceros no relacionados como resultado de una actividad externa, incluidos, entre otros, ataques de seguridad o fraude, nos reservamos la derecho a tomar medidas razonablemente apropiadas, incluidas, entre otras, investigaciones y denuncias, así como notificación y cooperación con las autoridades encargadas de hacer cumplir la ley. En el caso de una violación de datos, haremos todos los esfuerzos razonables para notificar a las personas afectadas si creemos que existe un riesgo razonable de daño para el usuario como resultado de la violación o si la ley exige que se notifique. Cuando lo hagamos, publicaremos un aviso en el sitio web y le enviaremos un correo electrónico.</p>
            <Title title='Aceptación de esta política' />
            <p>Usted reconoce que ha leído esta Política y acepta todos sus términos y condiciones. Al acceder y utilizar el sitio web y los servicios, usted acepta estar sujeto a esta Política. Si no acepta cumplir con los términos de esta Política, no está autorizado para acceder o utilizar el Sitio web y los Servicios.</p>
            <Title title='Contactándonos' />
            <p>Si desea comunicarse con nosotros para obtener más información sobre esta Política o si desea comunicarse con nosotros sobre cualquier asunto relacionado con los derechos individuales y su Información personal, puede enviar un correo electrónico a enquiries@westshoregroup.co.uk.</p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
