import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Photo from '../components/Photo'
import HowWeWork from '../components/HowWeWorkCard'
import { ReactComponent as Transition1 } from '../assets/transition1.svg'

import GonzaloFront from '../assets/photos/front/IMG_8879.jpg'
import MattFront from '../assets/photos/front/IMG_8912.jpg'
import GenesisFront from '../assets/photos/front/IMG_8922.jpg'
import IreneFront from '../assets/photos/front/IMG_8932.jpg'
import IsabelFront from '../assets/photos/front/IMG_8945.jpg'
import MihaiFront from '../assets/photos/front/IMG_8969.jpg'
import PaulaFront from '../assets/photos/front/IMG_8978.jpg'
import SandorFront from '../assets/photos/front/IMG_8988.jpg'
import FedeFront from '../assets/photos/front/IMG_4321.jpg'
import AlbaFront from '../assets/photos/front/IMG_4325.jpg'
import SofiaFront from '../assets/photos/front/IMG_4397.jpg'
import SandraFront from '../assets/photos/front/IMG_4403.jpg'

import GonzaloLateral from '../assets/photos/lateral/IMG_8964.jpg'
import MattLateral from '../assets/photos/lateral/IMG_8956.jpg'
import GenesisLateral from '../assets/photos/lateral/IMG_8951.jpg'
import IreneLateral from '../assets/photos/lateral/IMG_8941.jpg'
import IsabelLateral from '../assets/photos/lateral/IMG_8949.jpg'
import MihaiLateral from '../assets/photos/lateral/IMG_8972.jpg'
import PaulaLateral from '../assets/photos/lateral/IMG_8986.jpg'
import SandorLateral from '../assets/photos/lateral/IMG_8959.jpg'
import FedeLateral from '../assets/photos/lateral/IMG_4322.jpg'
import AlbaLateral from '../assets/photos/lateral/IMG_4327.jpg'
import SofiaLateral from '../assets/photos/lateral/IMG_4399.jpg'
import SandraLateral from '../assets/photos/lateral/IMG_4406.jpg'

import Writing from '../assets/emoji-writing.png'
import Bulb from '../assets/emoji-bulb.png'
import ThumbsUp from '../assets/emoji-thumbsup.png'
import Worker from '../assets/emoji-worker.png'
import Computer from '../assets/emoji-computer.png'

import React, { useEffect } from 'react'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'

export default function Us () {
  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])
  useTidioChat()

  const lineStyle = {
    left: '50%'
  }
  return (
    <div>
      <NavBar />
      <main className='pt-24'>
        <h1 className='pb-16 text-6xl md:text-8xl font-bold leading-tight text-center'>Quiénes Somos</h1>
        <div className='relative -mt-12 lg:-mt-24' />
        <Transition1 />
        <section className='bg-white px-1 py-6 sm:p-10'>
          <div className='reveal px-4 sm:px-0'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-4 sm:pt-0'>Nuestra Historia</h1>
            <div className='w-full mb-8'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
            </div>
            <div className='max-w-4xl mx-auto text-left'>
              <p className='text-gray-600 mb-3 sm:max-w-4xl mx-auto text-lg'>La historia de BlueHaus comienza cuando unos jóvenes arquitectos e ingenieros deciden emprender y mostrar sus habilidades en el campo residencial.</p>
              <p className='text-gray-600 mb-3 sm:max-w-4xl mx-auto text-lg'>Continuaron sus trabajos a la vez que se esforzaban en hacer crecer la empresa. Trabajaron duro en los primeros proyectos y tras un éxito aplastante decidieron dar el salto:<br />
              </p>
              <ul className='text-gray-600 list-disc ml-8 text-lg'>
                <li>Abrieron Oficinas</li>
                <li>Buscaron y ganaron clientes</li>
                <li>Contrataron y formaron a nuevos miembros</li>
              </ul>

              <p className='text-gray-600 sm:max-w-4xl mx-auto text-lg'><br />Ahora BlueHaus cuenta con mas de 500 proyectos completados, con más de 15 profesionales que hacen que continúe creciendo exponencialmente tanto en la oficina de Reino Unido como en la de España.</p>
            </div>
          </div>
        </section>

        <section className='bg-white p-1 sm:p-6'>
          <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-9 sm:pt-0 reveal'>Cómo trabajamos</h1>
          <div className='w-full mb-8'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t reveal' />
          </div>

          <div className='mx-auto w-full h-full max-w-4xl'>
            <div className='relative wrap overflow-hidden pb-10 pt-1 h-full'>
              <div className='z-0 border-2-2 absolute border-opacity-20 border-gray-700 h-full border rounded-t' style={lineStyle} />
              <div className='mb-8 flex justify-between flex-row-reverse items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>1</h1>
                </div>
                <HowWeWork
                  title='Planeamiento' text='Nos reunimos con el cliente asignándole un profesional que estará a su disponibilidad durante todo el proceso. Recopilamos la máxima información sobre el proyecto que se desea construir.
                      Marcamos las prioridades e intereses del cliente.' image={Bulb} number='1'
                />
              </div>
              <div className='mb-8 flex justify-between items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>2</h1>
                </div>
                <HowWeWork
                  title='Diseño' text='El equipo de arquitectos e ingenieros analiza las diferentes alternativas, ajustándose siempre al presupuesto. Se comparten las ideas con el cliente, se intercambian comentarios y propuestas hasta que obtenemos el diseño final.' image={Writing} number='2'
                />
              </div>
              <div className='mb-8 flex justify-between flex-row-reverse items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>3</h1>
                </div>
                <HowWeWork
                  title='Desarrollo' text='Se desarrolla el proyecto. Ya sea proyecto básico, de ejecución o de legalización.' image={Computer} number='3'
                />
              </div>
              <div className='mb-8 flex justify-between items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>4</h1>
                </div>
                <HowWeWork
                  title='Tramitación' text='Se gestionan los permisos y trámites administrativos necesarios.' image={ThumbsUp} number='4'
                />
              </div>
              <div className='mb-8 flex flex-row-reverse justify-between items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>5</h1>
                </div>
                <HowWeWork
                  title='Construcción' text='Una vez obtenidos los permisos o licencias le recomendaremos las mejores constructoras de la zona para que puedan terminar el proceso constructivo lo antes posible y con la calidad adecuada.' image={Worker} number='5'
                />
              </div>
            </div>
          </div>
        </section>

        <section className='bg-white p-1 sm:p-6'>
          <div className='reveal'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-9 sm:pt-0'>Nuestro Equipo</h1>
            <div className='w-full mb-8'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
            </div>
            <div className='mx-auto max-w-4xl'>
              <div className='grid gap-12 md:grid-cols-3'>
                <Photo name='Paula Cerro' position='Ingeniera Estructural' photo={PaulaFront} hoverPhoto={PaulaLateral} text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur imperdiet magna lorem, eget pharetra nisi efficitur interdum. Pellentesque in eros.' />
                <Photo name='Matias Ornaghi' position='Ingeniero Estructural' photo={MattFront} hoverPhoto={MattLateral} text='Titulado Ingeniero Civil y Máster en Planificación y Gestión de proyectos. Cuenta con años de experiencia como Jefe de Obra en obras de infraestructuras públicas y privadas. Con interés en el diseño, construcción y coordinación de proyectos de arquitectura.' />
                <Photo name='Irene Cardenete' position='Arquitecta' photo={IreneFront} hoverPhoto={IreneLateral} text='Arquitecta habilitada por la Universidad Politécnica de Valencia. Con interés en proyectos residenciales de escala doméstica y reformas de viviendas. Aficionada del diseño y el arte.' />
                <Photo name='Mihai Leustean' position='Ingeniero Estructural' photo={MihaiFront} hoverPhoto={MihaiLateral} text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur imperdiet magna lorem, eget pharetra nisi efficitur interdum. Pellentesque in eros.' />
                <Photo name='Génesis Pestana' position='Arquitecta' photo={GenesisFront} hoverPhoto={GenesisLateral} text='Apasionada por el diseño de interiores y la combinación de materiales. Con gran interés por la representación gráfica de la arquitectura y la creación de modelos 3D para la visualización realista de los espacios.' />
                <Photo name='Sandor González' position='Ingeniero Estructural' photo={SandorFront} hoverPhoto={SandorLateral} text='Titulado Ingeniero Civil y Máster en Planificación y Gestión de proyectos. Cuenta con años de experiencia como Jefe de Obra en obras de infraestructuras públicas y privadas. Con interés en el diseño, construcción y coordinación de proyectos de arquitectura.' />
                <Photo name='Isabel Albert' position='Coordinadora de Proyectos' photo={IsabelFront} hoverPhoto={IsabelLateral} text='Es la persona con la que estarás en contacto directo para resolver cualquier duda que pueda surgir durante el proceso productivo. Perfeccionista y apasianada por las cosas bien hechas, así será tu proyecto.' />
                <Photo name='Gonzalo Rojas' position='Ingeniero Estructural' photo={GonzaloFront} hoverPhoto={GonzaloLateral} text='Ingeniero Civil egresado en la provincia de San Juan, Argentina y actual estudiante del Máster en Representación y Diseño en Ingeniería y Arquitectura. Cuenta con más de 5 años  de experiencia como profesor universitario y más de 2 años en cálculo de estructuras privadas. Apasionado por las estructuras sismorresistentes.' />
                <Photo name='Sofía Caprio' position='Ingeniera Estructural' photo={SofiaFront} hoverPhoto={SofiaLateral} text='' />
                <Photo name='Federico Urban' position='Arquitecto' photo={FedeFront} hoverPhoto={FedeLateral} text='' />
                <Photo name='Alba Guerra' position='Arquitecta' photo={AlbaFront} hoverPhoto={AlbaLateral} text='' />
                <Photo name='Sandra Ruiz' position='Arquitecta' photo={SandraFront} hoverPhoto={SandraLateral} text='' />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
