import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Services from './pages/Services'
import Expats from './pages/Expats'
import Inmobiliarias from './pages/Inmobiliarias'
import Contact from './pages/Contact'
import Us from './pages/Us'
import FourOfour from './pages/404'
import Privacy from './pages/Privacy'
import Reformas from './pages/Reformas'
import Cookies from './pages/CookiePolicy'
import ReactGA from 'react-ga4'

// Initialize Google Analytics 4
if (process.env.REACT_APP_ENVIRONMENT === 'production') { ReactGA.initialize('G-2CXNX3BMZE') }

const rootElement = document.getElementById('root')
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='servicios' element={<Services />} />
      <Route path='expats' element={<Expats />} />
      <Route path='inmobiliarias' element={<Inmobiliarias />} />
      <Route path='contacto' element={<Contact />} />
      <Route path='nosotros' element={<Us />} />
      <Route path='politicas-privacidad' element={<Privacy />} />
      <Route path='reformas' element={<Reformas />} />
      <Route path='cookies' element={<Cookies />} />
      <Route path='*' element={<FourOfour />} />
    </Routes>
  </BrowserRouter>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
