import { ReactComponent as Transition1 } from '../assets/transition1.svg'
import { ReactComponent as FinalSuccess } from '../assets/trophy-target-success.svg'
import { ReactComponent as Whatsapp } from '../assets/whatsapp-green.svg'
import { ReactComponent as Email } from '../assets/mail-blue.svg'
import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Title from '../components/Title'
import ContactFormModal from '../components/ContactFormModal'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'
import WhyChooseUsCard from '../components/WhyChooseUsCard'
import { InlineWidget } from 'react-calendly'

import Trophy from '../assets/emoji-trophy.png'
import Target from '../assets/emoji-target.png'
import Handshake from '../assets/emoji-handshake.png'
import UKFlag from '../assets/emoji-uk_flag.png'
import Calculator from '../assets/emoji-calculator.png'
import ReformaPng from '../assets/blueprints-ukflag-dark.png'

import StructuralEngineeringImg from '../assets/photos/expats/structural-engineering-services.png'
import ConsultancyImg from '../assets/photos/expats/consultancy.png'
import NewBuildImg from '../assets/photos/expats/new-build.jpg'
import OnSiteImg from '../assets/photos/expats/on-site-assistance.png'
import PlanningPermissionImg from '../assets/photos/expats/planning-permission.png'
import RefurbishmentImg from '../assets/photos/expats/refurbishment.jpg'

export default function Expats () {
  const [contactFormModalShow, setContactFormModalShow] = useState(false)

  const isMobileScreen = window.innerWidth < 768

  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])

  useTidioChat()

  const lineStyle = {
    left: '50%'
  }

  const circleImageLeft = (urlImage) => {
    return isMobileScreen
      ? {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '20vw',
          height: '20vw',
          minWidth: '15rem',
          minHeight: '9rem',
          borderTopRightRadius: '1rem',
          borderBottomRightRadius: '1rem',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'
        }
      : {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '25vw',
          height: '17vw',
          minWidth: '9rem',
          minHeight: '9rem',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'

        }
  }
  const circleImageRight = (urlImage) => {
    return isMobileScreen
      ? {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '20vw',
          height: '20vw',
          minWidth: '15rem',
          minHeight: '9rem',
          borderTopLeftRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          alignSelf: 'flex-end',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'
        }
      : {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '25vw',
          height: '17vw',
          minWidth: '9rem',
          minHeight: '9rem',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'

        }
  }

  return (
    <div>
      <ContactFormModal showModal={contactFormModalShow} setShowModal={setContactFormModalShow} />
      <NavBar />
      <main className='pt-24'>
        <div className='max-w-6xl pb-24 pt-6 sm:py-6 mx-auto flex flex-wrap flex-col md:flex-row items-center'>
          {/* left */}
          <div className='flex flex-col w-full md:w-2/5 md:items-start text-center md:text-left basis-1/2'>
            <h1 className='my-4 mx-2 sm:mx-0 font-bold leading-tight text-[2.5rem] sm:text-4xl lg:text-5xl reveal'>Looking for Construction Experts?</h1>
            {isMobileScreen &&
              <div className='w-full mx-auto text-center basis-1/2 max-w-sm'>
                <img alt='reformas' src={ReformaPng} />
              </div>}
            <div className='max-w-6xl pb-24 pt-6 sm:py-6 mx-auto flex flex-wrap flex-col md:flex-row items-center reveal'>
              <p className='w-full leading-normal text-xl mt-8 mb-4 sm:mt-0 sm:mb-8 px-2 sm:px-0'>Are you an expat looking to refurbish your apartment or to make a smart investment in property? We can help you!</p>
              <p className='w-full leading-normal text-xl mt-4 sm:mt-0 mb-8 px-2 sm:px-0'>
                Our team of experts speak <strong>English</strong> and can
                guide you through the <strong>Spanish building processes</strong> easily.
              </p>
              <button
                className='mx-auto lg:mx-0 bg-[#fffc31] text-bh-black font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer sm:text-xl'
                onClick={() => setContactFormModalShow(true)}
              >
                Contact Us
              </button>
            </div>
          </div>
          {/* right */}
          {!isMobileScreen &&
            <div className='w-full py-8 text-center basis-1/2 max-w-xl'>
              <img alt='reformas' src={ReformaPng} />
            </div>}
        </div>
        <div className='relative -mt-12 lg:-mt-24'>
          <Transition1 />
        </div>

        {/** 2 **/}
        <section className='flex flex-col bg-white p-1 sm:px-6 sm:py-20 py-8 px-0 sm:place-content-evenly items-stretch sm:items-center gap-y-4 gap-x-4 justify-center pb-16'>
          <div className='max-w-5xl'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-9 sm:pt-0 reveal'>Realize your vision</h1>
            <div className='w-full mb-8'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t reveal' />
            </div>
            <p className='text-black max-w-5xl leading-normal text-center sm:text-initial text-xl sm:text-md mx-4 sm:mx-8 mb-8'>
              Look no further! <span className='text-[#1f33ac] font-bold text-xl sm:text-[1.5rem]'>BlueHaus </span>
              is here to provide you with <strong>top-notch architectural and
                engineering services
                                          </strong>, a team <strong>fluent in English</strong>,
              a wide network of <strong>skilled tradesmen</strong>, and
              <strong> on-site assistance</strong> to turn your vision into a reality.
            </p>
            <div className='flex flex-col sm:flex-row sm:flex-wrap items-stretch sm:items-center gap-y-4 gap-x-4 justify-center'>
              <div style={circleImageLeft(RefurbishmentImg)}>
                <p className='reveal font-black text-l text-center sm:text-[2vw] '>Refurbishment</p>
              </div>
              <div style={circleImageRight(PlanningPermissionImg)}>
                <p className='reveal font-black text-l text-center sm:text-[2vw] '>Change of Use</p>
              </div>
              <div style={circleImageLeft(NewBuildImg)}>
                <p className='reveal font-black text-l text-center sm:text-[2vw] leading-5 sm:leading-10'>New Build</p>
              </div>
              <div style={circleImageRight(StructuralEngineeringImg)}>
                <p className='reveal sm:px-4 font-black text-l text-center sm:text-[2vw] leading-5 sm:leading-5 md:leading-10'>Structural Engineering Services</p>
              </div>
              <div style={circleImageLeft(OnSiteImg)}>
                <p className='reveal font-black text-l text-center sm:text-[2vw] leading-5 sm:leading-10'>On-site Assistance</p>
              </div>
              <div style={circleImageRight(ConsultancyImg)}>
                <p className='reveal font-black text-l text-center sm:text-[2vw] leading-5 sm:leading-10'>Consulting</p>
              </div>
            </div>
          </div>
        </section>

        {/** 3 **/}
        {/* <section className='flex bg-white p-1 sm:px-6 sm:py-20 py-8 sm:place-content-evenly flex-col items-center gap-y-4'> */}
        {/*   {isMobileScreen && */}
        {/*     <p className='text-black leading-normal text-center sm:text-initial text-xl sm:text-2xl mx-4'> */}
        {/*       Look no further! <span className='text-[#1f33ac] font-bold text-xl sm:text-[1.6rem]'>Bluehaus </span> */}
        {/*       is here to provide you with <strong>top-notch architectural and */}
        {/*         engineering services */}
        {/*                                   </strong>, a team <strong>fluent in English</strong>, */}
        {/*       a wide network of <strong>skilled tradesmen</strong>, and */}
        {/*       <strong> on-site assistance</strong> to turn your vision into a reality. */}
        {/*     </p>} */}
        {/* </section> */}

        <section className='w-full bg-[#1f33ac]'>
          <div className='flex flex-col max-w-5xl mx-auto bg-[#1f33ac] p-1 sm:p-6 sm:py-10'>
            <h1 className='w-full text-4xl px-4 sm:px-0 font-bold leading-tight text-center text-white pt-9 sm:pt-0 '>
              Your dedicated Experts, even when you are not in Spain
            </h1>
            <p className='text-white mt-4 sm:mt-0 px-4 sm:px-0 text-xl sm:max-w-[55rem] sm:pt-8 mb-4 sm:mb-0 mx-auto text-center '>
              A team of dedicated professionals will help you to understand and navigate the building process in Spain.
              {isMobileScreen && <><br /><br /></>}
              {' '} Whether you are locally based or abroad, you can rely on our expertise.<br /><br /> No stress working with us!
            </p>
            <div className='flex flex-col sm:flex-row mb-8 sm:mb-0 ml-4 sm:ml-0 justify-center sm:justify-evenly align-center gap-y-4 sm:gap-x-4 pt-4 sm:pt-8 '>
              <div className='flex items-center justify-center gap-x-8 text-xl'>
                <a href='https://api.whatsapp.com/send?phone=34621333730' target='_blank' rel='noreferrer'>
                  <Whatsapp className='w-10 sm:w-16 h-auto hover:scale-110 transition ease-in-out duration-300' />
                </a>
                <a className='text-white' href='https://api.whatsapp.com/send?phone=34621333730' target='_blank' rel='noreferrer'>+34621333730</a>
              </div>
              <div className='flex items-center justify-center gap-x-8 text-xl'>
                <a href='mailto:info@bluehaus.es'>
                  <Email className='w-10 sm:w-16 h-auto hover:scale-110 transition ease-in-out duration-300' />
                </a>
                <p className='text-white'><a href='mailto:info@bluehaus.es' target='_blank' rel='noreferrer'>info@bluehaus.es</a> </p>
              </div>
            </div>
          </div>
        </section>

        {/** 4 **/}
        <section className='flex flex-col bg-white p-1 sm:p-6 sm:pb-16'>
          <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-16 sm:pt-14'>Book a Call!</h1>
          <div className='w-full mb-8 sm:mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <div>
            <InlineWidget
              url='https://calendly.com/bluehaus-design/30min'
              styles={{
                height: isMobileScreen ? '900px' : '800px'
              }}
              pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: false,
                hideLandingPageDetails: true,
                primaryColor: '00a2ff',
                textColor: '4d5055'
              }}
            />
          </div>
        </section>

        {/** 5 **/}
        {isMobileScreen &&
          <section className='bg-white p-1 sm:p-6 sm:pb-24'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-9 sm:pt-0 reveal'>
              {!isMobileScreen
                ? 'Why Choose BlueHaus?'
                : 'Why Us?'}
            </h1>
            <div className='w-full mb-8'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t reveal' />
            </div>
            <div className='mx-auto w-full h-full max-w-4xl'>
              <div className='flex flex-wrap gap-x-4 z-0 justify-center items-center relative wrap overflow-hidden pb-10 pt-1 h-full reveal'>
                <WhyChooseUsCard
                  title='Expertise'
                  image={Trophy}
                  isMobileScreen={isMobileScreen}
                />
                <WhyChooseUsCard
                  title='Personalized Approach'
                  image={Target}
                  isMobileScreen={isMobileScreen}
                />
                <WhyChooseUsCard
                  title='Communication'
                  image={UKFlag}
                  isMobileScreen={isMobileScreen}
                />
                <WhyChooseUsCard
                  title='Reliable Network'
                  image={Handshake}
                  isMobileScreen={isMobileScreen}
                />
                <WhyChooseUsCard
                  title='Cost Efficiency'
                  image={Calculator}
                  isMobileScreen={isMobileScreen}
                />
              </div>
            </div>
          </section>}

        {/** 6 **/}
        <section className='bg-bh-white py-16 sm:py-20'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center md:p-6 text-center sm:text-left basis-2/3'>
              <h3 className='font-bold text-2xl md:text-xl mx-4 sm:mx-0 lg:text-2xl text-gray-900'>
                Are you an Investor looking for Properties to Renovate in Spain?
              </h3>
              <div className='text-lg pt-4'>
                {isMobileScreen &&
                  <div className='flex justify-center w-full px-2 pb-4 sm:py-4 basis-1/3'>
                    <FinalSuccess className='w-72 sm:pt-4 sm:pt-0 sm:ml-8 h-auto' />
                  </div>}
                <p className='text-gray-600 mx-4 sm:mx-0 text-center sm:text-justify sm:text-left'>
                  At BlueHaus, we are dedicated to empowering expat investors like you.<br /><br />
                  We have a deep knowledge of the market, Spanish construction processes, and are committed to your success.<br /><br />
                  Join the ranks of satisfied expat investors who have partnered with us for their construction projects in Spain.
                </p>
                <button
                  className='mx-auto mt-8 sm:mt-4 bg-[#fffc31] text-bh-black font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer reveal'
                  onClick={() => setContactFormModalShow(true)}
                >
                  Contact Us
                </button>
              </div>
            </div>
            {!isMobileScreen &&
              <div className='flex justify-center w-full p-2 basis-1/3'>
                <FinalSuccess className='w-72 pt-4 sm:pt-0 sm:ml-8 h-auto' />
              </div>}
          </div>
        </section>

      </main>
      <Footer gray />
    </div>
  )
}
