import TextTransition, { presets } from 'react-text-transition'
import React, { useEffect } from 'react'

export default function RollingBanner ({ haveBorder }) {
  const [index, setIndex] = React.useState(0)
  const TEXTS = [
    '¿Proyecto de Obra Nueva?',
    'No vendas la Casa del Pueblo',
    '¿Quieres bañarte en tu piscina este verano?',
    '¿Interesado en las subvenciones para eficiencia energética?'
  ]

  const TEXTS2 = [
    '',
    '',
    'Consigue el diseño y la licencia de obra por el mejor precio',
    'Nosotros te orientamos y te lo tramitamos'
  ]

  const BUTTONS_TEXT = [
    'Empieza ahora',
    'Refórmala',
    'Contáctanos',
    'Llámanos'
  ]

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
    7000 // every 3 seconds
    )
    return () => clearTimeout(intervalId)
  })

  return (
    <section className={`bg-white ${haveBorder && 'border-2'}`}>
      <div className='mx-auto py-16 max-w-6xl'>
        <div className='pt-6 mx-auto min-h-[120px] lg:min-h-[113px]'>
          <div className='flex justify-center text-center'>
            <TextTransition
              className='font-bold text-2xl md:text-3xl lg:text-4xl text-dark-blue'
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.slow}
              overflow
              direction='down'
            />
          </div>
          <div className='hidden sm:flex justify-center text-center'>
            <TextTransition
              className='font-bold text-lg md:text-xl lg:text-2xl text-dark-blue'
              text={TEXTS2[index % TEXTS2.length]}
              springConfig={presets.slow}
              overflow
              direction='up'
            />
          </div>
        </div>
        <div className='flex justify-center text-center pb-4'>
          <a className='mx-auto lg:mx-0 hover:underline bg-[#fffc31] text-bh-black font-bold rounded-full my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out' href='contacto'>
            <TextTransition
              className='text-base md:text-lg lg:text-xl'
              text={BUTTONS_TEXT[index % BUTTONS_TEXT.length]}
              springConfig={presets.slow}
              overflow
            />
          </a>
        </div>
      </div>
    </section>
  )
}
