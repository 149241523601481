import './index.css'
import { ReactComponent as Cookie } from './assets/cookie.svg'

import CookieConsent from 'react-cookie-consent'
import Landing from './pages/Landing'

function App () {
  return (
    <div>
      <Landing />
      <CookieConsent
        disableStyles
        overlay
        buttonClasses='transition ease-in-out delay-50 border-none outline-none rounded-full text-xl text-white bg-gradient-to-r from-light-blue to-dark-blue p-3 mt-4 hover:-translate-y-1 duration-300'
        containerClasses='flex flex-col absolute z-[999] bg-white text-bh-black px-4 sm:rounded-lg sm:left-1/2 sm:top-1/2 sm:w-[340px] sm:h-[240px] sm:-ml-[170px] sm:-mt-[105px]'
        contentClasses='text-xl flex flex-col'
        buttonWrapperClasses='self-center mb-4'
        overlayClasses='fixed left-0 top-0 w-full h-full z-[999] bg-slate-600/75'
        buttonText='Entendido'
      >
        <Cookie className='w-auto h-24 -mt-14 mb-2' />
        <p className='text-xl font-bold text-center'>Antes de que entres a BlueHaus</p>
        <div className='mt-2'>
          <p className='text-sm'>
            Este sitio web utiliza cookies para prestar sus servicios y analizar el tráfico.
          </p>
          <p className='mt-4 text-xs'>
            Puedes leer más sobre nuestras cookies <a className='text-dark-blue underline' href='politica-cookies'>aquí</a>.
          </p>
        </div>
      </CookieConsent>
    </div>
  )
}

export default App
