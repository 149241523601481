import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { ReactComponent as Transition1 } from '../assets/transition1.svg'
import { ReactComponent as Phone } from '../assets/phone.svg'
import { ReactComponent as Whatsapp } from '../assets/whatsapp.svg'
import { ReactComponent as Email } from '../assets/email.svg'
import { ReactComponent as Clock } from '../assets/clock.svg'
import React, { useEffect } from 'react'
import ContactForm from '../components/ContactForm'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'
import { InlineWidget } from 'react-calendly'

export default function Contact () {
  const isMobileScreen = window.innerWidth < 768

  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])
  useTidioChat()

  return (
    <div>
      <NavBar />
      <main className='pt-24'>
        <h1 className='pb-16 text-6xl md:text-8xl font-bold leading-tight text-center'>Contacto</h1>
        <div className='relative -mt-12 lg:-mt-24' />
        <Transition1 />

        <section className='bg-white px-2 py-12'>
          <div className='reveal'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black'>Habla con nosotros</h1>
            <div className='w-full mb-4'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
            </div>
            <ContactForm />
          </div>
        </section>

        <section className='bg-white p-9'>
          <div className='reveal'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black'>O contáctanos directamente</h1>
            <div className='w-full mb-4'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
            </div>
            <ul className='list-reset mb-6 flex justify-evenly flex-col sm:flex-row md:justify-center sm:mt-6'>
              <li className='mx-auto sm:mx-0 mt-2 inline-block md:block md:mr-16'>
                <a className='text-bh-black text-2xl md:text-xl lg:text-2xl hover:underline' href='tel:+34 963 686 532'>
                  <Phone className='fill-dark-blue w-6 md:w-5 lg:w-6 inline mr-1' />+34 963 686 532
                </a>
              </li>
              <li className='mx-auto sm:mx-0 mt-2 inline-block md:block md:mr-16'>
                <a className='text-bh-black text-2xl md:text-xl lg:text-2xl hover:underline' href='https://wa.me/34621333730'>
                  <Whatsapp className='fill-dark-blue w-6 md:w-5 lg:w-6 inline mr-1' />+34 621 333 730
                </a>
              </li>
              <li className='mx-auto sm:mx-0 mt-2 inline-block md:block md:mr-0'>
                <a className='text-bh-black text-2xl md:text-xl lg:text-2xl hover:underline' href='mailto:info@bluehaus.es'>
                  <Email className='fill-dark-blue w-6 md:w-5 lg:w-6 inline mr-2' />info@bluehaus.es
                </a>
              </li>
            </ul>
            <p className='text-bh-black text-center text-2xl mt-9 md:text-xl lg:text-2xl'><Clock className='fill-dark-blue w-6 md:w-5 lg:w-6 inline mr-1' />Estamos abiertos de 9h a 18h de lunes a viernes</p>
          </div>
        </section>

        <section className='bg-white px-3 py-12'>
          <div className='reveal max-w-4xl mx-auto'>
            <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black'>¿Dónde nos encontramos?</h1>
            <div className='w-full mb-4'>
              <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
            </div>
            <iframe className='w-full h-96 md:w-2/3 md:mx-auto sm:mt-8' src='https://maps.google.com/maps?q=BlueHaus,%20Oficinas%20L/N,%20Carrer%20de%20les%20Garrigues,%20piso%207,%20Valencia,%20Spain&t=&z=17&ie=UTF8&iwloc=&output=embed' frameborder='0' scrolling='no' marginheight='0' marginwidth='0' title='map' />
          </div>
        </section>

        {/** 4 **/}
        <section className='flex flex-col bg-white p-1 sm:p-6 sm:pb-16'>
          <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-16 sm:pt-14'>Solicita una cita</h1>
          <div className='w-full mb-8'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <div>
            <InlineWidget
              url='https://calendly.com/bluehaus-design/30min'
              styles={{
                height: isMobileScreen ? '900px' : '800px'
              }}
              pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: false,
                hideLandingPageDetails: true,
                primaryColor: '00a2ff',
                textColor: '4d5055'
              }}
            />
          </div>
        </section>

      </main>
      <Footer />
    </div>
  )
}
