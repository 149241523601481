export default function WhyChooseUsCard ({ title, image, text, isMobileScreen }) {
  return (
    isMobileScreen
      ? <div className='min-w-fit z-20 mt-4 mb-4 sm:mb-12 sm:my-4 order-1 px-6 py-4 mx-1 sm:mx-0'>
        <div className='flex items-center -mt-12'>
          <img src={image} className='w-auto h-8 mt-4 mr-2' alt='thinking face' />
          <h2 className='text-gray-800 text-lg mt-4 sm:mt-10 font-semibold '>{title}</h2>
        </div>
      </div>
      : <div className='min-w-fit z-20 mt-8 mb-4 sm:mb-12 sm:my-4 order-1 px-6 py-4 mx-1 sm:mx-0'>
        <div className='flex flex-col items-center -mt-12'>
          <img src={image} className='w-auto h-14 mt-4' alt='thinking face' />
          <h2 className='text-gray-800 max-w-[10ch] text-xl mt-4 sm:mt-10 font-semibold text-center'>{title}</h2>
        </div>
        </div>

  )
}
