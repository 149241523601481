import { ReactComponent as Transition1 } from '../assets/transition1.svg'
import { ReactComponent as Success } from '../assets/success.svg'
import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Title from '../components/Title'
import ContactFormModal from '../components/ContactFormModal'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'
import HowWeWork from '../components/HowWeWorkCard'

import Writing from '../assets/emoji-writing.png'
import Bulb from '../assets/emoji-bulb.png'
import Bricks from '../assets/emoji-bricks.png'
import Calculator from '../assets/emoji-calculator.png'
import PartyPopper from '../assets/emoji-party-popper.png'
import ReformaPng from '../assets/reforma.png'

import BanosImg from '../assets/photos/reformas/banos.jpg'
import CocinaImg from '../assets/photos/reformas/cocina.jpg'
import ReformaIntegralImg from '../assets/photos/reformas/reforma-integral.jpg'
import ReformaEstructuralImg from '../assets/photos/reformas/reforma-estructural.jpeg'

export default function Reformas () {
  const [contactFormModalShow, setContactFormModalShow] = useState(false)

  const isMobileScreen = window.innerWidth < 768

  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])

  useTidioChat()

  const lineStyle = {
    left: '50%'
  }

  const circleImageLeft = (urlImage) => {
    return isMobileScreen
      ? {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '20vw',
          height: '20vw',
          minWidth: '15rem',
          minHeight: '9rem',
          borderTopRightRadius: '1rem',
          borderBottomRightRadius: '1rem',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'
        }
      : {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '20vw',
          height: '20vw',
          minWidth: '9rem',
          minHeight: '9rem',
          borderRadius: '50%',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'

        }
  }
  const circleImageRight = (urlImage) => {
    return isMobileScreen
      ? {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '20vw',
          height: '20vw',
          minWidth: '15rem',
          minHeight: '9rem',
          borderTopLeftRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          alignSelf: 'flex-end',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'
        }
      : {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`,
          backgroundSize: 'cover',
          width: '20vw',
          height: '20vw',
          minWidth: '9rem',
          minHeight: '9rem',
          borderRadius: '50%',
          backgroundPosition: 'center center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease 0s',
          boxShadow: 'rgba(0,0,0,0.54) 15px 14px 13px -14px',
          webkitUserDrag: 'none',
          userSelected: 'none'

        }
  }

  return (
    <div>
      <ContactFormModal showModal={contactFormModalShow} setShowModal={setContactFormModalShow} />
      <NavBar />
      <main className='pt-24'>
        <div className='max-w-5xl pb-24 pt-6 sm:py-6 mx-auto flex flex-wrap flex-col md:flex-row items-center'>
          {/* left */}
          <div className='flex flex-col w-full md:w-2/5 md:items-start text-center md:text-left basis-1/2'>
            <h1 className='my-4 font-bold leading-tight text-3xl sm:text-4xl lg:text-5xl '>Reforma y Renueva</h1>
            <h1 className='my-1 font-bold leading-tight text-3xl sm:text-4xl lg:text-5xl '>Tu espacio, tu estilo </h1>
            {isMobileScreen &&
              <div className='w-full mx-auto text-center basis-1/2 max-w-sm'>
                <img alt='reformas' src={ReformaPng} />
              </div>}
            <p className='w-full leading-normal text-xl mt-8 mb-2'>¿Llevas tiempo pensando en reformar? Este es tu momento.</p>
            {/* <p className='w-full leading-normal text-xl mb-2 '>Este es tu momento.</p> */}
            <p className='w-full leading-normal text-xl mb-8 px-2'> Obtén el resultado profesional que buscas con nosotros.</p>
            <button
              className='mx-auto lg:mx-0 bg-[#fffc31] text-bh-black font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer sm:text-xl'
              onClick={() => setContactFormModalShow(true)}
            >
              Cuéntanos más
            </button>
          </div>
          {/* right */}
          {!isMobileScreen &&
            <div className='w-full py-8 text-center basis-1/2 max-w-xl'>
              <img alt='reformas' src={ReformaPng} />
            </div>}
        </div>
        <div className='relative -mt-12 lg:-mt-24'>
          <Transition1 />
        </div>

        {/** 2 **/}
        <section className='flex flex-col sm:flex-row bg-white p-1 sm:px-6 sm:py-20 py-8 px-0 sm:place-content-evenly items-stretch sm:items-center gap-y-4 gap-x-4 justify-center'>
          <div style={circleImageLeft(BanosImg)}>
            <p className='reveal font-black text-l text-center sm:text-[2vw] '>Baños</p>
          </div>
          <div style={circleImageRight(CocinaImg)}>
            <p className='reveal font-black text-l text-center sm:text-[2vw] '>Cocinas</p>
          </div>
          <div style={circleImageLeft(ReformaIntegralImg)}>
            <p className='reveal font-black text-l text-center sm:text-[2vw] leading-5 sm:leading-10'>Reformas Integrales</p>
          </div>
          <div style={circleImageRight(ReformaEstructuralImg)}>
            <p className='reveal font-black text-l text-center sm:text-[2vw] leading-5 sm:leading-10'>Reformas Estructurales</p>
          </div>
        </section>

        <section className='flex bg-white p-1 sm:px-6 sm:py-20 py-8 sm:place-content-evenly flex-col items-center gap-y-4'>
          {isMobileScreen
            ? <p className='text-black leading-normal text-center sm:text-initial text-xl sm:text-2xl mx-4'>
              En <span className='text-[#1f33ac] font-bold text-xl sm:text-[1.6rem]'>Bluehaus</span>, nos enfocamos en la transparencia, la innovación y la
              eficacia para brindar a nuestros clientes un <strong>proceso sencillo y sin complicaciones </strong>
              </p>
            : <><p className='text-black leading-normal text-xl sm:text-2xl'>
              En <span className='text-[#1f33ac] font-bold text-xl sm:text-[1.6rem]'>Bluehaus</span>, nos enfocamos en la transparencia, la innovación y la
              eficacia para<br />
                </p>
              <p className='text-black leading-normal text-xl sm:text-2xl'>
                brindar a nuestros clientes un <strong>proceso sencillo y sin
                  complicaciones
                </strong>
              </p>
              </>}

        </section>

        {/** 3 **/}
        <section className='bg-white p-1 sm:p-6 sm:pb-24'>
          <h1 className='w-full text-4xl font-bold leading-tight text-center text-bh-black pt-9 sm:pt-0'>¿Cómo lo hacemos?</h1>
          <div className='w-full mb-8'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
          </div>
          <div className='mx-auto w-full h-full max-w-4xl'>
            <div className='relative wrap overflow-hidden pb-10 pt-1 h-full'>
              <div className='z-0 border-2-2 absolute border-opacity-20 border-gray-700 h-full border rounded-t' style={lineStyle} />
              <div className='mb-8 flex justify-between flex-row-reverse items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>1</h1>
                </div>
                <HowWeWork
                  title='Planificación y visión' text='Nos cuentas tu idea, definimos tus objetivos y necesidades'
                  image={Bulb} number='1'
                />
              </div>
              <div className='mb-8 flex justify-between items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>2</h1>
                </div>
                <HowWeWork
                  title='Diseño y Conceptualización' text='Nuestros arquitectos desarrollan un diseño acorde a tus requerimientos concretando el estilo y los materiales'
                  image={Writing} number='2'
                />
              </div>
              <div className='mb-8 flex justify-between flex-row-reverse items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>3</h1>
                </div>
                <HowWeWork
                  title='Presupuesto' text='Nuestro equipo te ayudará a obtener un presupuesto competitivo y adaptado a tí'
                  image={Calculator} number='3'
                />
              </div>
              <div className='mb-8 flex justify-between items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>4</h1>
                </div>
                <HowWeWork
                  title='Ejecución' text='Los profesionales más cualificados y con experiencia comienzan las obras. Nuestros técnicos supervisan exhaustivamente el progreso'
                  image={Bricks} number='4'
                />
              </div>
              <div className='mb-8 flex flex-row-reverse justify-between items-center w-full right-timeline reveal'>
                <div className='hidden sm:block order-1 w-5/12' />
                <div className='z-20 hidden sm:flex items-center order-1 bg-gray-800 w-12 h-12 rounded-full'>
                  <h1 className='mx-auto font-semibold text-2xl text-bh-white'>5</h1>
                </div>
                <HowWeWork
                  title='Inspección final y disfrute' text='¡Es hora de disfrutar de tu nueva reforma!'
                  image={PartyPopper} number='5'
                />
              </div>
            </div>
          </div>
        </section>

        {/** 4 **/}
        <section className='bg-white py-16 sm:py-20'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto '>
            <div className='flex flex-col justify-center md:p-6 text-center sm:text-left basis-2/3'>
              <Title text='Calidad Garantizada' />
              <div className='text-lg pt-4'>
                {isMobileScreen &&
                  <div className='flex justify-center w-full px-2 py-4 basis-1/3'>
                    <Success className='w-72 pt-4 sm:pt-0 sm:ml-8 h-auto' />
                  </div>}
                <p className='text-gray-600 text-center sm:text-justify sm:text-left'>
                  El éxito de Bluehaus radica en la <strong>experiencia </strong>de sus profesionales y en la <strong>atención excepcional</strong> que brindamos a nuestros clientes.<br /><br />
                  Contáctanos y experimenta el servicio de calidad que nos distingue.<br /><br />
                </p>
                <div className='flex text-center pb-4'>
                  <a className='mx-auto lg:mx-0 hover:underline gradient text-white rounded-full my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out' href='contacto'>
                    <p className='text-base md:text-lg lg:text-xl'>
                      Pide Presupuesto
                    </p>
                  </a>
                </div>
              </div>
            </div>
            {!isMobileScreen &&
              <div className='flex justify-center w-full p-2 basis-1/3'>
                <Success className='w-72 pt-4 sm:pt-0 sm:ml-8 h-auto' />
              </div>}
          </div>
        </section>

        {/** 5 **/}
        <section className='bg-bh-white '>
          <div className='flex flex-col mx-auto py-16 max-w-6xl'>
            <div className='pt-6 mx-auto min-h-[120px] lg:min-h-[113px]'>
              <div className='flex justify-center text-center'>
                <h3 className='font-bold text-xl md:text-xl lg:text-2xl text-gray-900'>
                  ¿Simplemente buscas un fontanero o electricista?
                </h3>
              </div>
              <div className='my-4 sm:flex justify-center text-center'>
                <p className='font-medium text-m md:text-l lg:text-xl text-gray-600 mx-auto'>
                  Te ponemos en contacto con los mejores profesionales.
                </p>
              </div>
            </div>
            <button
              className='mx-auto bg-[#fffc31] text-bh-black font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer reveal'
              onClick={() => setContactFormModalShow(true)}
            >
              Saber más
            </button>
          </div>
        </section>

      </main>
      <Footer gray />
    </div>
  )
}
