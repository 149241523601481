import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ContactForm from './ContactForm'

import { ReactComponent as X } from '../assets/x-close.svg'

export default function ContactFormModal ({ showModal, setShowModal }) {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => setShowModal(false)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-80' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto px-2'>
          <div className='min-h-full items-center justify-center text-center flex flex-row'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='transform overflow-hidden text-left flex-grow max-w-lg'>

                <p className='w-full text-4xl font-bold leading-tight text-center text-bh-white pt-4 sm:pt-0'>Hablemos</p>
                <div className='w-full mb-2'>
                  <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t' />
                </div>
                <button className='float-right mt-1 mr-1' onClick={() => setShowModal(false)}><X /></button>
                <ContactForm func={() => setShowModal(false)} />

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
