import React, { useState } from 'react'
import { useFormik } from 'formik'
const axios = require('axios').default

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Requerido'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de correo inválida'
  }

  if (!values.message) {
    errors.message = 'Requerido'
  } else if (values.message.length > 600) {
    errors.message = 'Debe tener 600 caracteres o menos'
  }

  return errors
}

export default function ContactForm (props) {
  const [view, setView] = useState('init')
  const [disabled, setDisabled] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      message: ''
    },
    validate,
    onSubmit: values => {
      // send us an email
      let data = new FormData()
      data.append('from', `${values.name} <${values.email}>`)
      data.append('to', 'info@bluehaus.es')
      data.append('subject', 'Nueva Consulta')
      data.append('html', `
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>New Enquiry</title>
      </head>
      <body>
          <h1>Nueva Consulta</h1>
          <table>
              <tr>
                  <th>Nombre</th>
                  <td>${values.name}</td>
              </tr>
              <tr>
                  <th>Móvil</th>
                  <td>${values.mobile}</td>
              </tr>
              <tr>
                  <th>Email</th>
                  <td>${values.email}</td>
              </tr>
              <tr>
                  <th>Mensaje</th>
                  <td>${values.message}</td>
              </tr>
          </table>
      </body>
      </html>`)

      const promise1 = axios({
        url: process.env.REACT_APP_MAILGUN_URL,
        method: 'post',
        headers: { Authorization: process.env.REACT_APP_MAILGUN_API_KEY },
        data
      })

      // sends client a confirmation email
      data = new FormData()
      data.append('from', 'BlueHaus <no-reply@bluehaus.es>')
      data.append('to', values.email)
      data.append('subject', '¡Hemos recibido tu mensaje!')
      data.append('html', `<html style="font-family: 'Open Sans', sans-serif; font-size: 14px; background-color: #ECF0F1;">
      <div style="margin-top: 4rem; text-align: center;">
          <img style="margin: 0 auto;" src="https://westshore-uploads.s3.eu-west-2.amazonaws.com/bluehaus-logo.png" alt="logo"
              width="250" />
      </div>
      <div
          style="margin: 2rem auto 0.5rem;  padding: 2rem; background-color: white; border-radius: 12px; max-width: 600px;">
          <h1 style="margin-top: 0; text-align: center; font-size: 30px">¡Gracias por tu consulta!</h1>
          <p style="text-align: left; font-size: 16px; font-weight: 500;">Hola${values.name ? ' ' + values.name + ' 👋' : ' 👋'}</p>
      <p style = "font-size: 16px; font-weight: 500;"> Nuestro equipo ha recibido tu mensaje.
Revisarán la información y se pondrán en contacto contigo en menos de 24hrs</p >
      </div >
      <div
          style="margin: 0.5rem auto 0.5rem;  padding: 2rem; background-color: white; border-radius: 12px; max-width: 600px;">
          <h1 style="margin-top: 0; font-size:  20px;">Si se trata de un asunto urgente</h1>
          <p style="font-size: 16px; font-weight: 500;">Por favor contacte directamente con nosotros llamando al 963 686 532.</p>
      </div>
      <div
      style="margin: 0.5rem auto 2rem;  padding: 2rem; background-color: white; border-radius: 12px; max-width: 600px;">
      <h1 style="margin-top: 0; font-size: 20px;">Durante la espera</h1>
      <p style="font-size: 16px; font-weight:500;">Puede disfrutar de interesantes post de ingeniería y arquitectura echándole un ojo a nuestras rides sociales</p>
      <div style="text-align: center;">
          <a href="https://www.facebook.com/BlueHaus.es"><img style="margin: 0 0.5rem 0 0.5rem"
                  src="https://westshore-uploads.s3.eu-west-2.amazonaws.com/facebook.png" alt="facebook"
                  width="40" /></a>
          <a href="https://www.instagram.com/bluehaus.es"><img style="margin: 0 0.5rem 0 0.5rem"
                  src="https://westshore-uploads.s3.eu-west-2.amazonaws.com/instagram.png" alt="instagram"
                  width="40" /></a>
      </div>
  </div>
      <div style="max-width: 600px; margin:auto; text-align:center; font-size: 11px; color: #2F2F41;">
          <p style="margin-bottom: 0;">© BlueHaus 2022.</p>
          <p style="margin-top: 0; margin-bottom: 2rem;">Calle Garrigues 5, Oficina L-M, Valencia España</p>
      </div>
  </html > `)

      const promise2 = axios({
        url: process.env.REACT_APP_MAILGUN_URL,
        method: 'post',
        headers: { Authorization: process.env.REACT_APP_MAILGUN_API_KEY },
        data
      })

      const scriptUrl = 'https://script.google.com/macros/s/AKfycbxBHhI-V4szw1rsiuTMmrzXLHnEvl5yoNmOf61h_6c1kzpTxXsPIJaw7Qy0iwNtqZTE4A/exec'
      data = new FormData()
      data.append('Date', new Date().toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }))
      data.append('Name', values.name)
      data.append('Phone', values.phone)
      data.append('Email', values.email)
      data.append('Message', values.message)

      fetch(scriptUrl, { method: 'POST', body: data })
        .then(res => {
          Promise.all([promise1, promise2])
            .then(values => {
              setView('success')
              formik.resetForm()
            })
            .catch(error => {
              setView('error')
              console.log(error)
            })
        })
        .catch(err => console.log(err))

      setDisabled(true)
    }
  })

  if (view === 'init') {
    return (
      <div className='w-full max-w-xl mx-auto'>
        <form id='contactForm' onSubmit={formik.handleSubmit} className='bg-bh-white shadow-md rounded px-1 py-6 mb-4 flex-col sm:px-8 h-5/6'>
          <div className='mb-4 sm:flex sm:justify-between'>
            <div className='grow sm:mr-1 mb-4 sm:mb-0'>
              <label htmlFor='name' className='block text-gray-700 text-sm font-bold mb-2'>Nombre</label>
              <input
                id='name'
                name='name'
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                placeholder='María Romero'
              />
            </div>
            <div className='grow sm:ml-1'>
              <label htmlFor='mobile' className='block text-gray-700 text-sm font-bold mb-2'>Móvil</label>
              <input
                id='mobile'
                name='mobile'
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                placeholder='633 555 555'
              />
            </div>
          </div>
          <div className='mb-4'>
            <label htmlFor='email' className='block text-gray-700 text-sm font-bold mb-2'>Correo Electrónico*</label>
            <input
              id='email'
              name='email'
              type='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className='shadow appearance-none border rounded w-full py-2 px-3 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              placeholder='mariaromero@gmail.com'
            />
            {formik.touched.email && formik.errors.email ? <div className='text-red-500 h-3 text-xs'>{formik.errors.email}</div> : <div className='h-3' />}
          </div>
          <div className='mb-4'>
            <label htmlFor='message' className='block text-gray-700 text-sm font-bold mb-2'>Mensaje*</label>
            <textarea
              id='message'
              name='message'
              type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              rows='4'
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              placeholder='Describa brevemente su proyecto'
            />
            {formik.touched.message && formik.errors.message ? <div className='text-red-500 h-3 text-xs'>{formik.errors.message}</div> : <div className='h-3' />}
            <p className='text-gray-700 text-right'>
              (*) campos obligatorios
            </p>
          </div>
          <div className='flex justify-center'>
            {disabled === true
              ? <button type='submit' className='mx-auto lg:mx-0 bg-gray-400 text-white font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline cursor-not-allowed' disabled> <svg role='status' class='inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='currentColor' /><path d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z' fill='#1C64F2' /></svg>Enviando</button>
              : <button type='submit' className='mx-auto lg:mx-0 gradient text-white font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer'>Enviar</button>}
          </div>
        </form>
      </div>
    )
  }
  if (view === 'success') {
    return (
      <div className='w-full mx-auto bg-white p-7 rounded-lg h-[483px] flex flex-col justify-center'>
        <div class='animation-ctn'>
          <div class='icon icon--order-success svg'>
            <svg xmlns='http://www.w3.org/2000/svg' width='154px' height='154px' className='mx-auto'>
              <g fill='none' stroke='#22AE73' stroke-width='2'>
                <circle cx='77' cy='77' r='72' className='circle' />
                <circle id='colored' fill='#22AE73' cx='77' cy='77' r='72' className='circle' />
                <polyline class='st0' stroke='#fff' stroke-width='10' points='43.5,77.8 63.7,97.9 112.2,49.4 ' className='polyline' />
              </g>
            </svg>
          </div>
        </div>

        <h1 className='w-full text-3xl font-bold leading-tight text-center text-bh-black mt-2'>¡Mensaje enviado con exito!</h1>
      </div>
    )
  }
  if (view === 'error') {
    return (
      <div>
        <h1 className='text-3xl font-bold text-center'>Error enviando el mensaje</h1>
      </div>
    )
  }
};
