import { ReactComponent as Whatsapp } from '../assets/whatsapp.svg'
import { ReactComponent as Instagram } from '../assets/instagram.svg'
import { ReactComponent as Facebook } from '../assets/facebook.svg'
import { ReactComponent as Transition2 } from '../assets/transition2.svg'
import { ReactComponent as Transition2Gray } from '../assets/transition2-gray.svg'
import { ReactComponent as Phone } from '../assets/phone.svg'
import { ReactComponent as Email } from '../assets/email.svg'

export default function Footer ({ gray }) {
  return (
    <footer>
      {gray
        ? <Transition2Gray />
        : <Transition2 />}
      <div className='container mx-auto px-8'>
        <div className='w-full flex flex-row pt-6 max-w-5xl mx-auto'>
          <div className='sm:basis-1/3 mb-6 text-black hidden sm:block'>
            <a href='/' className='flex items-center flex-shrink-0 text-bh-white mr-12 cursor-pointer'>
              <svg className='fill-current h-9 w-9 mr-3' viewBox='0 0 17.55 17.55'><rect className='cls-1' x='7.34' y='14.38' width='3.22' height='3.17' /><polygon className='cls-1' points='17.55 6.89 8.78 0 0 6.89 0 17.55 3.93 17.55 3.93 8.01 7.34 5.58 7.34 11.52 10.57 11.52 10.57 5.58 13.98 8.01 13.98 17.55 17.55 17.55 17.55 6.89' /></svg>
              <span className='font-semibold text-2xl tracking-tight'>BlueHaus</span>
            </a>
            <div className='flex mt-8'>
              <a href='https://www.instagram.com/bluehaus.es/' target='_blank' className='flex-initial mr-4' rel='noreferrer'><Instagram className='w-10 sm:w-12 fill-bh-white hover:fill-dark-blue' /></a>
              <a href='https://www.facebook.com/BlueHaus.es' target='_blank' className='flex-initial mr-4' rel='noreferrer'><Facebook className='w-10 sm:w-12 fill-bh-white hover:fill-dark-blue' /></a>
            </div>
          </div>
          <div className='sm:basis-1/3 basis-1/2'>
            <p className='uppercase text-bh-white font-medium md:mb-6'>Enlaces</p>
            <ul className='list-reset mb-6 flex justify-evenly flex-col md:block'>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a href='/nosotros' className='no-underline hover:underline text-bh-white text-sm sm:text-base'>Nosotros</a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a href='/servicios' className='no-underline hover:underline text-bh-white text-sm sm:text-base'>Servicios</a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a href='/contacto' className='no-underline hover:underline text-bh-white text-sm sm:text-base'>Contacto</a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a href='/politicas-privacidad' className='no-underline hover:underline text-bh-white text-sm sm:text-base'>Políticas de Privacidad</a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a href='/cookies' className='no-underline hover:underline text-bh-white text-sm sm:text-base'>Cookies</a>
              </li>
            </ul>
          </div>
          <div className='sm:basis-1/3 basis-1/2 min-w-[145px]'>
            <p className='uppercase text-bh-white font-medium md:mb-6'>Contacto</p>
            <ul className='list-reset mb-6 flex justify-evenly flex-col md:block'>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a className='text-bh-white hover:underline text-sm sm:text-base' href='tel:+34 963 686 532'>
                  <Phone className='fill-bh-white w-5 sm:w-6 inline mr-1' />+34 963 686 532
                </a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a className='text-bh-white hover:underline text-sm sm:text-base' href='https://wa.me/34621333730' target='_blank' rel='noreferrer'>
                  <Whatsapp className='fill-bh-white w-5 sm:w-6 inline mr-2' />+34 621 333 730
                </a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <a className='text-bh-white hover:underline text-sm sm:text-base' href='mailto:info@bluehaus.es'>
                  <Email className='fill-bh-white w-5 sm:w-6 inline mr-2' />info@bluehaus.es
                </a>
              </li>
              <li className='mt-2 inline-block md:block md:mr-0'>
                <p className='text-bh-white text-sm'>Horario: L-V 9:00 - 18:00</p>
              </li>
            </ul>
          </div>
        </div>
        <div className='flex justify-center mb-4 sm:hidden'>
          <a href='https://www.instagram.com/bluehaus.es/' target='_blank' className='flex-initial mr-4' rel='noreferrer'><Instagram className='w-10 sm:w-12 fill-bh-white hover:fill-dark-blue' /></a>
          <a href='https://www.facebook.com/BlueHaus.es' target='_blank' className='flex-initial mr-4' rel='noreferrer'><Facebook className='w-10 sm:w-12 fill-bh-white hover:fill-dark-blue' /></a>
        </div>
        <p className='text-bh-white text-xs mx-auto w-auto flex justify-center text-center pb-4'>© BlueHaus 2024. Todos los derechos reservados</p>
      </div>
    </footer>
  )
}
