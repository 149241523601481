import React from 'react'

export default function InfoLink ({ children, button, setContactFormModalShow }) {
  const [visible, setVisible] = React.useState(false)
  return (
    <div className='flex flex-col gap-x-4'>
      {visible && children}
      <div className='flex gap-x-4'>
        <button
          className={`font-medium text-xl text-blue-600 hover:cursor-pointer
                    ${(button && visible) && 'mt-8'}`}
          onClick={() => setVisible(!visible)}
        >
          <span className='hover:underline text-lg'>
            {visible ? 'Ocultar' : 'Mostrar más'}
          </span>
          ▸
        </button>
        <button
          className='text-xl text-[#1f33ac] font-semibold hover:cursor-pointer'
          onClick={() => setContactFormModalShow(true)}
        >
          <span className='hover:underline text-lg'>
            Contacta
          </span>
          ▸
        </button>
      </div>
    </div>
  )
}
