import { useState } from 'react'

export default function Subtitle ({ name, position, text, photo, hoverPhoto }) {
  const [showText, setShowText] = useState(false)
  function over (event) {
    event.target.src = hoverPhoto
  }
  function out (event) {
    event.target.src = photo
  }

  if (showText) {
    return (
      <div className='space-y-4 text-center'>
        {/* <div className='cursor-pointer w-64 h-64 mx-auto rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80 flex items-center' onClick={() => setShowText(!showText)}> */}
        <div className='cursor-pointer w-64 h-64 mx-auto rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80 flex items-center'>
          <span className='text-dark-blue '>
            {text}
          </span>
        </div>
        <div>
          <h4 className='text-2xl text-dark-blue'>{name}</h4>
          <span className='block text-sm text-gray-500 font-medium'>{position}</span>
        </div>
      </div>
    )
  } else {
    return (
      <div className='space-y-4 text-center'>
        <img
          // className='cursor-pointer w-64 h-64 mx-auto object-cover object-top rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80' onMouseOver={over} onMouseOut={out} onClick={() => setShowText(!showText)}
          className='cursor-pointer w-64 h-64 mx-auto object-cover object-top rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80' onMouseOver={over} onMouseOut={out}
          src={photo} alt='man' loading='lazy'
        />
        <div>
          <h4 className='text-2xl text-dark-blue font-medium'>{name}</h4>
          <span className='block text-sm text-gray-500 font-medium'>{position}</span>
        </div>
      </div>
    )
  }
}
