import { ReactComponent as Robot } from '../assets/404.svg'

import React, { useEffect } from 'react'
import Tracking from '../tracking'

export default function Privacy () {
  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()
  }, [])

  return (
    <div>
      <main>
        <section className='bg-bh-white h-screen'>
          <h1 className='text-dark-blue pt-16 pb-2 text-6xl  font-bold leading-tight text-center'>Página no encontrada</h1>
          <Robot className='h-[35rem] w-auto mx-auto' />
        </section>
      </main>
    </div>
  )
}
