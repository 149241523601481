import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Title from '../components/TitleWithLine'
import SubTitle from '../components/Subtitle'

import { ReactComponent as Transition1 } from '../assets/transition1.svg'

import React, { useEffect } from 'react'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'

export default function Privacy () {
  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])
  useTidioChat()

  return (
    <div>
      <NavBar />
      <main className='pt-24'>
        <h1 className='pb-16 text-6xl md:text-8xl font-bold leading-tight text-center'>Cookies</h1>
        <div className='relative -mt-12 lg:-mt-24' />
        <Transition1 />
        <section className='bg-white px-1 py-6 sm:p-10'>
          <div className='max-w-4xl mx-auto text-gray-600 text-justify'>
            <p>Esta política de cookies ("Política") describe qué son las cookies y cómo están siendo utilizadas por el sitio web bluehaus.es ("Sitio web" o "Servicio") y cualquiera de sus productos y servicios relacionados (colectivamente, "Servicios") . Esta Política es un acuerdo legalmente vinculante entre usted ("Usuario", "usted" o "su") y Westshore Engineering Ltd., el propietario de la marca BlueHaus ("Westshore Engineering Ltd.", "nosotros" o " nuestro"). Debe leer esta Política para que pueda comprender los tipos de cookies que utilizamos, la información que recopilamos mediante cookies y cómo se utiliza esa información. También describe las opciones disponibles para usted con respecto a aceptar o rechazar el uso de cookies. Para obtener más información sobre cómo usamos, almacenamos y mantenemos seguros sus datos personales, consulte nuestra Política de privacidad.</p>
            <Title title='¿Qué son las cookies?' />
            <p className='mb-4'>Las cookies son pequeños fragmentos de datos almacenados en archivos de texto que se guardan en su computadora u otros dispositivos cuando los sitios web se cargan en un navegador. Se usan ampliamente para recordarlo a usted y sus preferencias, ya sea para una sola visita (a través de una "cookie de sesión") o para múltiples visitas repetidas (usando una "cookie persistente").</p>
            <p className='mb-4'>Las cookies de sesión son cookies temporales que se utilizan durante el curso de su visita al sitio web y caducan cuando cierra el navegador web.</p>
            <p className='mb-4'>Las cookies persistentes se utilizan para recordar sus preferencias dentro de nuestro sitio web y permanecen en su escritorio o dispositivo móvil incluso después de cerrar su navegador o reiniciar su computadora. Aseguran una experiencia consistente y eficiente para usted mientras visita el sitio web y los servicios.</p>
            <p>Las cookies pueden ser establecidas por el sitio web ("cookies de origen"), o por terceros, como aquellos que publican contenido o brindan publicidad o servicios de análisis en el sitio web ("cookies de terceros"). Estos terceros pueden reconocerlo cuando visita nuestro sitio web y también cuando visita otros sitios web.</p>
            <Title title='¿Qué tipos de cookies utilizamos?' />
            <SubTitle text='Cookies necesarias' />
            <p>Las cookies necesarias nos permiten ofrecerle la mejor experiencia posible al acceder y navegar por nuestro sitio web y utilizar sus funciones. Por ejemplo, estas cookies nos permiten reconocer que ha creado una cuenta y ha iniciado sesión en esa cuenta para acceder al contenido.</p>
            <SubTitle text='Cookies de funcionalidad' />
            <p>Las cookies de funcionalidad nos permiten operar el sitio web y los servicios de acuerdo con las elecciones que realice. Por ejemplo, reconoceremos su nombre de usuario y recordaremos cómo personalizó el sitio web y los servicios durante futuras visitas.</p>
            <SubTitle text='Cookies analíticas' />
            <p>Estas cookies nos permiten a nosotros y a los servicios de terceros recopilar datos agregados con fines estadísticos sobre cómo nuestros visitantes usan el sitio web. Estas cookies no contienen información personal, como nombres y direcciones de correo electrónico, y se utilizan para ayudarnos a mejorar su experiencia de usuario del sitio web.</p>
            <SubTitle text='Cookies publicitarias' />
            <p>Las cookies publicitarias nos permiten a nosotros y a terceros mostrarle anuncios relevantes de manera más efectiva y nos ayudan a recopilar datos de auditoría agregados, investigaciones e informes de rendimiento para los anunciantes. También nos permiten comprender y mejorar la entrega de anuncios para usted y saber cuándo se le han mostrado ciertos anuncios. Su navegador web puede solicitar publicidad directamente de los servidores de la red publicitaria, estas redes pueden ver, editar o establecer sus propias cookies, como si hubiera solicitado una página web desde su sitio web. Aunque no usamos cookies para crear un perfil de su comportamiento de navegación en sitios web de terceros, sí usamos datos agregados de terceros para mostrarle publicidad relevante basada en intereses.</p>
            <SubTitle text='Cookies de redes sociales' />
            <p>Las cookies de terceros de sitios de redes sociales (como Facebook, Twitter, etc.) nos permiten rastrear a los usuarios de redes sociales cuando visitan o usan el sitio web y los servicios, o comparten contenido, mediante el uso de un mecanismo de etiquetado proporcionado por esas redes sociales. Estas cookies también se utilizan con fines de seguimiento de eventos y remarketing. Todos los datos recopilados con estas etiquetas se utilizarán de acuerdo con nuestras políticas de privacidad y las de las redes sociales. No recopilaremos ni compartiremos ninguna información de identificación personal del usuario.</p>
            <SubTitle text='¿Utilizamos balizas web o píxeles de seguimiento?' />
            <p className='mb-4'>Nuestros correos electrónicos pueden contener una "baliza web" (o "píxel de seguimiento") para indicarnos si nuestros correos electrónicos están abiertos y verificar cualquier clic a través de enlaces o anuncios dentro del correo electrónico.</p>
            <p className='mb-4'>Podemos utilizar esta información para fines que incluyen determinar cuáles de nuestros correos electrónicos son más interesantes para los usuarios y para consultar si los usuarios que no abren nuestros correos electrónicos desean continuar recibiéndolos.</p>
            <p>El píxel se eliminará cuando elimine el correo electrónico. Si no desea que el píxel se descargue en su dispositivo, debe leer el correo electrónico en vista de texto sin formato o con las imágenes deshabilitadas.</p>
            <SubTitle text='¿Cuáles son sus opciones de cookies?' />
            <p>Si no le gusta la idea de las cookies o ciertos tipos de cookies, puede cambiar la configuración de su navegador para eliminar las cookies que ya se han configurado y no aceptar nuevas cookies. Para obtener más información sobre cómo hacer esto o para obtener más información sobre las cookies, visite internetcookies.org</p>
            <SubTitle text='Cambios y enmiendas' />
            <p>Nos reservamos el derecho de modificar esta Política o sus términos relacionados con el Sitio web y los Servicios en cualquier momento, a partir de la publicación de una versión actualizada de esta Política en el Sitio web. Cuando lo hagamos, revisaremos la fecha actualizada en la parte inferior de esta página. El uso continuado del sitio web y los servicios después de dichos cambios constituirá su consentimiento a dichos cambios.</p>
            <SubTitle text='Aceptación de esta política' />
            <p>Usted reconoce que ha leído esta Política y acepta todos sus términos y condiciones. Al acceder y utilizar el sitio web y los servicios, usted acepta estar sujeto a esta Política. Si no acepta cumplir con los términos de esta Política, no está autorizado para acceder o utilizar el Sitio web y los Servicios.</p>
            <SubTitle text='Contactándonos' />
            <p>Si desea comunicarse con nosotros para obtener más información sobre esta Política o si desea comunicarse con nosotros con respecto a cualquier asunto relacionado con nuestro uso de cookies, puede enviar un correo electrónico a enquiries@westshoregroup.co.uk</p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
