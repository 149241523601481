export default function InfoLink ({ text, url, color, isContacta, setContactFormModalShow, margin }) {
  if (!setContactFormModalShow) {
    return (
      <a className={`${margin && 'mr-4'} ${color && `text-[#{${color}}]`} ${isContacta ? ' font-semibold ' : ' font-medium '} text-xl`} href={url}><span className='hover:underline text-lg'>{text}</span>▸</a>
    )
  }
  return (
    <a
      className={`${margin && 'mr-4'}
${color && ` text-[#${color}] `}
${isContacta ? ' font-semibold ' : ' font-medium '}
text-xl`} href={url}
    ><span className='hover:underline text-lg cursor-pointer' onClick={() => setContactFormModalShow(true)}>{text}</span>▸
    </a>
  )
}
