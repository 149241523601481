export default function HowWeWorkCard ({ title, image, text, number }) {
  return (
    <div className='z-20 bg-bh-white rounded-lg mt-8 mb-12 sm:my-4 order-1 sm:w-5/12 px-6 py-4 mx-2 sm:mx-0'>
      <div className='flex flex-col-reverse items-center -mt-12'>
        <img src={image} className='w-auto h-14 mt-4' alt='thinking face' />
        <div className='flex sm:hidden z-20 items-center order-1 bg-gray-800 w-8 h-8 rounded-full'>
          <h1 className='mx-auto font-semibold text-lg text-bh-white'>{number}</h1>
        </div>
      </div>
      <div>
        <h2 className='text-gray-800 text-3xl font-semibold text-center'>{title}</h2>
        <p className='mt-2 text-center sm:text-initial text-gray-600 text-lg'>{text}
        </p>
      </div>
    </div>
  )
}
